import React, { useMemo } from "react";
import { connect } from "react-redux";
import "./MovieCardLongWeb.scss";
import { useAnalytics } from "use-analytics";
import { ROUTES } from "helpers/routeHelper";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { Link } from "react-router-dom";

const MovieCardLongWeb = ({ movie, screen }) => {
  const { track, page, identify } = useAnalytics();
  const trackEvent = () => {
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.MOVIE_CARD,
        screen,
        {
          Card: "LongWeb",
          MovieId: movie?.id,
          Title: movie?.title,
          Type: movie?.multipart ? 'Series' : 'Movie'
        }
      )
    );
  };
  const url = useMemo(() =>
    movie?.multipart
      ? ROUTES.SERIES_INFO.replace(":movieId", movie?.id)
      : ROUTES.MOVIE_INFO.replace(":movieId", movie?.id)
  );
  return (
    <>
      <Link
        to={{
          pathname: url,
        }}
        routerDirection="forward"
        onClick={() => trackEvent()}
      // className="shine"
      >
        <div className="section one">

          <img className="movie-card-long-img" src={movie?.banners?.[0]} alt="poster" width='100%' height='100%'/>

        </div>
      </Link>
    </>
  );
};

const mapStateToProps = ({ user, app }) => ({
  userId: user?.userId,
  isAuthUser: user?.isAuthUser,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MovieCardLongWeb);
