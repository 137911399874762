import React, { useState, useMemo } from "react";
import "./MovieCardNormal.scss";
import { Link } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import { ROUTES } from "helpers/routeHelper";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import DiamondOutline from "assets/img/diamond.svg";
import SkeletonLoader from "modules/common/components/SkeletonLoader/SkeletonLoader";

const MovieCardNormal = ({ movie, screen, query }) => {
  const { track, page, identify } = useAnalytics();
  const trackEvent = () => {
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.MOVIE_CARD,
        screen,
        { 
          Card: "Normal",
          MovieId: movie?.id,
          Title: movie?.title,
          Type: movie?.multipart ? 'Series' : 'Movie',
          SearchQuery: query || '',
       }
      )
    );
  };
  const url = useMemo(() =>
    movie?.multipart
      ? ROUTES.SERIES_INFO.replace(":movieId", movie?.id)
      : ROUTES.MOVIE_INFO.replace(":movieId", movie?.id)
  );

  return (
    <>
      <Link
        to={{
          pathname: url,
          state: { movie: movie },
        }}
        routerDirection="forward"
        onClick={() => trackEvent()}
        className="cardlinking"
      >
        <div className="movie-normal">
          {movie?.paid && (
            <div className="flag">
              <img src={DiamondOutline} alt="diamond" />
            </div>
          )}

          <SkeletonLoader srcName={movie?.banners?.[0]} />

          <div className="text-movie-normal-cont">
            <div className="mr-grid">
              <h5 className="movie-normal-title">
                {movie?.title?.slice(0, 32)}
              </h5>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default MovieCardNormal;
