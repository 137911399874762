import React, { useState, useEffect, useMemo } from "react";
import "./MovieList.scss";
import {
  IonLoading,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from "@ionic/react";
import { useAnalytics } from "use-analytics";
import MovieCarousel from "modules/common/components/MovieCarousel/MovieCarousel";
import { movieSkeleton } from "constants/skeletonConstant";
import { connect } from "react-redux";
import {
  getFeaturedMovie,
  getMovieListData,
} from "store/slices/persistSlice/persistSlice";
import { useHistory, useParams } from "react-router-dom";
import { findGetParameter, ROUTES } from "helpers/routeHelper";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { PageTitleCd, setPageTitle } from "helpers/seoHelper";
import { BUSINESS_CONFIG, MOVIE_SUB_TYPES } from "../../../../clientConfig";
import { Helmet } from "react-helmet";
import { PageWrapperComponent } from "modules/common";

const MovieList = ({
  featuredMovieList,
  movieList,
  getMovieListDataAction,
  getFeaturedMovieAction,
  savedSubType,
}) => {
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { page } = useAnalytics();
  const [moviesList, setMovieList] = useState(movieSkeleton.list);
  const [featuredMovies, setFeaturedMovies] = useState(movieSkeleton.featured);
  const [showSkeleton, setShowSkeleton] = useState({
    short: true,
    long: true,
    normal: true,
  });

  const _filter = () => {
    let filter = findGetParameter("filter");
    if (!filter) {
      filter = params?.filter;
    }
    filter = MOVIE_SUB_TYPES.find(
      (x) => x.value.toLowerCase() == filter?.toLowerCase()
    )?.value;
    return filter;
  };

  useIonViewWillEnter(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_LIST);
  });

  useIonViewDidEnter(() => {
    const filter = _filter();
    // if (!filter) {
    //   history.replace(ROUTES.HOME);
    // }
    const genre = movieList?.map((x) => x?.name).join(", ");
    setPageTitle("MOVIE_LIST", filter + " | " + genre);
    setIsLoading(savedSubType !== filter);
    getMovieListDataAction(filter);
    getFeaturedMovieAction();
  });

  useEffect(() => {
    if (featuredMovieList) {
      setFeaturedMovies(featuredMovieList);
      setShowSkeleton((showSkeleton) => ({
        ...showSkeleton,
        long: false,
      }));
    }
  }, [featuredMovieList]);

  useEffect(() => {
    setIsLoading(savedSubType !== _filter());
    if (movieList) {
      setMovieList(movieList);
      setShowSkeleton((showSkeleton) => ({
        ...showSkeleton,
        short: false,
        normal: false,
      }));
    }
  }, [movieList]);

  const keywords = useMemo(() => {
    const titles = movieList
      ?.map((x) => x?.values)
      ?.reduce((acc, val) => acc.concat(val), [])
      .map((x) => x?.title)
      ?.join(", ");
    return titles;
  }, [movieList]);

  return (
    <>
      {
        <Helmet>
          <meta
            content={`${BUSINESS_CONFIG.Title}${PageTitleCd?.["MOVIE_LIST"] ||
              PageTitleCd.HOME} || ${keywords}`}
            name="description"
          />
          {/* <meta content={keywords} name="keywords" /> */}
        </Helmet>
      }
      <PageWrapperComponent>
        <IonLoading
          isOpen={isLoading}
          onDidDismiss={() => setIsLoading(false)}
          message={"Please wait..."}
          duration={5000}
        />
        {featuredMovies?.map((data, i) =>
          data?.values?.length ? (
            <MovieCarousel
              key={i}
              carkey={i + showSkeleton?.long}
              moviesList={data?.values}
              name={data?.name}
              screen={"MovieListing"}
              isBanner={true}
              showSkeleton={showSkeleton}
              showVideo={false}
            />
          ) : (
            <></>
          )
        )}
        {moviesList?.map((data, i) =>
          data?.values?.length ? (
            <MovieCarousel
              key={i}
              carkey={i + `${showSkeleton?.short || showSkeleton?.normal}`}
              isBanner={false}
              moviesList={data?.values}
              name={data?.name}
              screen={"MovieListing"}
              showSkeleton={showSkeleton}
              showVideo={false}
            />
          ) : (
            <></>
          )
        )}
      </PageWrapperComponent>
    </>
  );
};

const mapStateToProps = ({ persist }) => ({
  featuredMovieList: persist?.featuredMovies?.data,
  movieList: persist?.movieList?.data,
  savedSubType: persist?.movieList?.subType,
});
const mapDispatchToProps = {
  getMovieListDataAction: getMovieListData,
  getFeaturedMovieAction: getFeaturedMovie,
};
export default connect(mapStateToProps, mapDispatchToProps)(MovieList);
