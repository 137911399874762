/* no-nested-ternary */
import React, { useEffect, useState } from "react";
import "./MoviePayment.scss";
import {
  IonButton,
  isPlatform,
} from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { APP_THEME, PAYMENT } from "../../../../clientConfig";

const MoviePayment = ({
  closePaymentPopUp,
  tier,
  setTier,
  setIsSubscription,
  hidePayPerView,
  setPaymentGateway,
  showPaymentGateway,
  userPlans,
}) => {
  const getTiers = () => [
    {
      title: "Watch now in HD",
      subTitle:
        "Best suited for computer, tv and mobile screen with 4g internet connection",
      value: "tier1",
    },
    {
      title: " Watch now in 4k",
      subTitle:
        "Best suited for computer, tvs with minimum 10 Mbs brodband connect",
      value: "tier2",
    },
  ];

  const [tiers, setTiers] = useState(getTiers());

  const onSubscriptionClick = (value) => {
    setTier(value);
    setIsSubscription(true);
    // closePaymentPopUp(false);
  };

  const onPayPerViewClick = (value) => {
    setTier(value);
    setIsSubscription(false);
    // closePaymentPopUp(false);
  };

  const gateways = () => userPlans?.find((x) => x.period === tier)?.gateways;

  return (
    <>
      {showPaymentGateway && tier ? (
        <div
          className="movie-payment"
          // onClick={handleClick}
          id="movie-payment-shadow"
        >
          <div className="page-main-img">
            <img
              src="https://nine-rasa-all.ams3.digitaloceanspaces.com/Assets/Frame%20124.png"
              alt="main"
            />
          </div>
          <div className="payment-content">
            <div className="payment-heading">
              Please select your payment gateway:
            </div>
            <div className="movie-payment-methods">
              <div className="action-wrap">
                {gateways()?.map((gateway) => {
                  if (
                    (gateway?.name === "playstore_billing" ) &&
                    ( !Capacitor.isNativePlatform() || isPlatform("ios") )
                  ) {
                    return null;
                  }
                  if (
                    gateway?.name === "appstore_billing" &&
                    ( !Capacitor.isNativePlatform() || isPlatform("android") )
                  ) {
                    return null;
                  }
                  if(Capacitor.isNativePlatform() && isPlatform("ios") && gateway?.name === "razorpay") {
                    return null;
                  }
                  return (
                    <button
                      className="payment-btn-active"
                      onClick={() => {
                        setPaymentGateway(gateway?.name);
                      }}
                    >
                      Pay with {gateway?.name?.replace("_"," ")?.toUpperCase()}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : PAYMENT.IS_PAY_PER_VIEW ? (
        <div
          className="movie-payment"
          // onClick={handleClick}
          id="movie-payment-shadow"
        >
          <div className="movie-payment-methods">
            {tiers?.map((t, i) => (
              <div className="action-wrap" key={i}>
                <div className="payment-heading">{t?.title}</div>
                <div className="payment-desc">{t?.subTitle}</div>

                <button
                  key={i}
                  className="payment-btn-active"
                  onClick={() => {
                    onPayPerViewClick(t?.value);
                  }}
                >
                  {t?.title}
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="subscription-wrap">
          <div className="page-main-img">
            <img
              src="https://nine-rasa-all.ams3.digitaloceanspaces.com/Assets/Frame%20124.png"
              alt="main"
            />
          </div>
          <div className="content-section">
            <div className="subscription-heading">Subscriptions</div>
            <div className="subscription-desc">
              <p>
                Enjoy 30 Days Free Of Our Exclusively Curated Premium Content By
                Subscribing.
              </p>
              <p>
                To avail the same and support the cause of INDIAN THEATRE please
                choose a subscription mode of your preference.
              </p>
            </div>
            <div className="plans-container">
              <div className="plan-heading">Plans</div>
              <div className="plan-box-wrap">
                {userPlans?.map((plan) => (
                  <div className="plan-box">
                    <div className="pricing">
                      {plan?.name?.toUpperCase()}:{" "}
                      <span>{plan?.currency || "Rs"}{" "} {plan?.display_amount}</span>
                    </div>
                    <IonButton
                      className="btnprimary"
                      color="secondary"
                      shape="round"
                      size="default"
                      onClick={() => onSubscriptionClick(plan?.period)}
                    >
                      Buy Now
                    </IonButton>
                  </div>
                ))}
              </div>
            </div>
            <div className="disclaimer">
              Please note that a token sum of upto INR 10 only will be deducted from
              your account to validate your subscription details. You may choose
              to deactivate the same twenty four hours prior to your renewal
              date if you may so wish.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MoviePayment;
