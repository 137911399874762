import React, { useEffect, useMemo, useState } from "react";
import "./Home.scss";
import { useAnalytics } from "use-analytics";
import { homeSkeleton } from "constants/skeletonConstant";
import { connect } from "react-redux";
import { setDefault } from "store/slices/appSlice";
import {
  getFeaturedMovie,
  getHomeCarousel,
} from "store/slices/persistSlice/persistSlice";
import { MOVIE_SUB_TYPES, SHOW_HOME_FILTER } from "clientConfig";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { setPageTitle } from "helpers/seoHelper";
import { Helmet } from "react-helmet";
import {
  FilterTabsComponent,
  MovieCarouselComponent,
  PlansPaymentPopupComponent,
  PageWrapperComponent
} from "modules/common";
import { FILTER_TYPE } from "clientConfig";
import { FILTER_TYPE_CODE } from "constants/constants";

const Home = ({
  featuredMovieList,
  homeCarouselList,
  setDefaultAction,
  getHomeCarouselAction,
  getFeaturedMovieAction,
  isMobile,
  isAuthUser,
}) => {
  const filterOptions = MOVIE_SUB_TYPES;

  const { track, page, identify } = useAnalytics();
  const [carouselData, setCarouselData] = useState(homeSkeleton.list);
  const [featuredMovies, setFeaturedMovies] = useState(homeSkeleton.featured);
  const [filter, setFilter] = useState(0);
  const [showSkeleton, setShowSkeleton] = useState({
    short: true,
    long: true,
    normal: true,
  });
  const [showPlansPayment, setPlansPaymentShow] = useState(false);

  const checkPaymentPopup = () => {
    const showSubscribePopup = localStorage.getItem("showSubscribePopup");
    if (showSubscribePopup === "1") {
      setTimeout(() => {
        setPlansPaymentShow(true);
      }, 1200);
    }
  };

  useEffect(() => {
    checkPaymentPopup();
  }, [isAuthUser]);

  useEffect(() => {
    setPageTitle("HOME");
    page(TRACK_EVENT.APP_USAGE.SCREEN.HOME);
    setDefaultAction();
    getHomeCarouselAction();
    getFeaturedMovieAction();
  },[]);

  useEffect(() => {
    if (featuredMovieList) {
      setFeaturedMovies(featuredMovieList);
      setShowSkeleton((showSkeleton) => ({
        ...showSkeleton,
        long: false,
      }));
    }
  }, [featuredMovieList]);

  useEffect(() => {
    if (homeCarouselList) {
      setCarouselData(homeCarouselList);
      setShowSkeleton((showSkeleton) => ({
        ...showSkeleton,
        short: false,
        normal: false,
      }));
    }
  }, [homeCarouselList]);

  const keywords = useMemo(() => {
    const titles = homeCarouselList
      ?.map((x) => x?.values)
      ?.reduce((acc, val) => acc.concat(val), [])
      .map((x) => x?.title)
      ?.join(", ");
    const genres = homeCarouselList?.map((x) => x?.name);
    return titles + ", " + genres;
  }, [homeCarouselList]);

  return (
    <>
      {
        <Helmet>
          <meta content={keywords} name="keywords" />
        </Helmet>
      }
      <PlansPaymentPopupComponent
          setShow={(val) => {
            setPlansPaymentShow(val);
            localStorage.removeItem("showSubscribePopup");
          }}
          show={showPlansPayment}
        />
      <PageWrapperComponent>
        {/* {FILTER_TYPE == FILTER_TYPE_CODE.SUBTYPE && isMobile && SHOW_HOME_FILTER && ( */}
        <FilterTabsComponent
          filter={filter}
          setFilter={setFilter}
          filterList={filterOptions}
        />
        {/* )} */}
        {featuredMovies?.map((data, i) =>
          data?.values?.length ? (
            <MovieCarouselComponent
              key={`home-carousel-movie-${i}${data?.name}`}
              carkey={i + showSkeleton?.long}
              moviesList={data?.values}
              name={data?.name}
              screen={TRACK_EVENT.APP_USAGE.SCREEN.HOME}
              isBanner={true}
              showSkeleton={showSkeleton}
              loop={true}
            />
          ) : (
            <></>
          )
        )}
        {carouselData?.map((data, i) =>
          data?.values?.length ? (
            <MovieCarouselComponent
              key={`home-carousel-movie-${i}${data.name}`}
              carkey={i + `${showSkeleton?.short || showSkeleton?.normal}`}
              isBanner={false}
              moviesList={data?.values}
              name={data?.name}
              screen={TRACK_EVENT.APP_USAGE.SCREEN.HOME}
              showSkeleton={showSkeleton}
              loop={true}
            />
          ) : (
            <></>
          )
        )}
      </PageWrapperComponent>
    </>
  );
};

const mapStateToProps = ({ user, app, persist }) => ({
  featuredMovieList: persist?.featuredMovies?.data,
  homeCarouselList: persist?.homeCarousel?.data,
  isAuthUser: user?.isAuthUser,
  isMobile: app?.isMobile,
  isAuthUser: user?.isAuthUser,
});
const mapDispatchToProps = {
  setDefaultAction: setDefault,
  getHomeCarouselAction: getHomeCarousel,
  getFeaturedMovieAction: getFeaturedMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
