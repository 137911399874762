import { CONTENT_RATING } from "constants/constants";

export const APP_HEADER_TITLE = "Ott";
export const BUSINESS_NAME = "ott";
export const APP_FOOTER = "ott";
export const BUSINESS_ID = 2;
export const APP_THEME = "greentheme";

export const ENABLE_ADS_BLOCKER = false;

export const LOGO = 'https://flowgiga.ams3.cdn.digitaloceanspaces.com/demo_assets/logo_files/flowgiga_logo_big_with_text_landscape.png';
export const SMALL_LOGO = 'https://flowgiga.ams3.cdn.digitaloceanspaces.com/demo_assets/logo_files/Flowgiga_logo_no_text_portrait.png';
export const RAZORPAY_THEME = '#EFCF02';
export const BASE_URL = "https://ninerasa.flowgiga.com";
// APP BAckend API
// export const APP_BACKEND_API = "https://dev.flowgiga.com/ninerasa";
export const APP_BACKEND_API = "https://demo-backend.flowgiga.com/website";
// APP Analytics API
export const ANALYTICS_BACKEND_API =
  "https://demo-backend.flowgiga.com/dynamo/analytics";
export const BUSINESS_CONFIG = {
  BusinessName: "ninerasa",
  BusinessId: 2,
  GoogleAnalytics_TrackingId : 'UA-180736466-1',
  PrivacyPolicy: `${APP_BACKEND_API}/privacy_policy`,
  RazorPayKey: 'rzp_live_9Pcv2nBBhk8LOb',
  Title: 'Nine Rasa | Theatre Online | Watch Indian Theatre Plays Online'
}
export const APP_LINK = {
  ANDROID: 'https://play.google.com/store/apps/details?id=io.ninerasa.flowgiga.app',
  IOS: 'https://apps.apple.com/in/app/ninerasa/id1569663582'
}
// to hide the social sign in/up buttons
export const SHOW_SOCIAL_AUTH = false;
export const SHOP_URL = 'https://ninerasa.wixsite.com/my-site';
export const GAME_URL = 'https://flowgiga.com';
// initilze the payment types
export const PAYMENT = {
  IS_SUBSCRIPTION: true,
  IS_PAY_PER_VIEW: false
};

// to show the sub types filter in mobile
// export const FILTER_TYPE = 'subtype';
export const FILTER_TYPE = 'multipart';
// to show the sub types filter in mobile
export const SHOW_HOME_FILTER = true;
// List of filters/Sub types in mobile home and Desktop header
export const MOVIE_SUB_TYPES = [
  { label: "All", value: "All" },
  { label: "Movies", value: "Movies" },
  { label: "Webseries", value: "Webseries" },
  // { label: "Stories", value: "Stories" },
  // { label: "Documentary", value: "Documentary" },
  // { label: "Standup", value: "Standup" },
  // { label: "Shortseries", value: "Shortseries" },
  // { label: "Limitedseries", value: "Limitedseries" },
  // { label: "Normal", value: "Normal" },
  // { label: "Shortfilm", value: "Shortfilm" }
];

// To show/hide the tabs in mobile
export const SHOW_TABS = {
  HOME: true,
  MOVIES_LIST: FILTER_TYPE != 'subtype',
  SERIES_LIST: FILTER_TYPE != 'subtype',
  COINS: false,
  USER_PROFILE: false,
  EXPLORE: false,
  SHOP: false,
  GAME: true,
  ACCOUNT: false
};

// to show the sub types filter in mobile
export const SHOW_MULTIPART_FILTER = true;


export const META_KEYWORDS = 'original theatre online, scary stories to tell in the dark, vinay pathak, live theatre shows near me, national theatre live plays, live stage shows, ram prasad ki tehrvi watch ott, death of a salesman, death of a salesman 1985, tughlaq durbar, shreyas talpade, pashmina, shreyas talpade movie list, shreyas talpade taj mahal movie, shreyas talpade movie, aa namo bahu nade chhe, ajab karamat, chel chabilo gujarati, duniya ne undha chasma, dikri vahal no dariyo, best gujarati natak comedy, marathi theatre, gujjubhai ni golmaal, gupchup gupchup, gujjubhai e gaam gajavyu, new ott, best ott 2021, new on ott, new ott 2021, latest on ott, upcoming ott, new on ott this week, to mi navech, kusum manohar lele, sakharam binder, savita damodar paranjpe, indian classical drama, indian folk theatre, mohit takalkar, theatre artists in india, how to learn acting, how to learn acting at home, how to learn acting at home free, indian theatre actors, how to learn acting online, indian theatre directors, differences between theatre and drama, live theatre performances near me, marathi natak list, marathi natak comedy list, marathi natak list free download, marathi drama list, marathi plays list, marathi natak list 2019, marathi sangeet natak list, marathi vinodi natak list, famous indian dramatists, live stage shows';

// add the object for id, which you want to show warnong on watch click
export const CONTENT_WATCH_WARNING= [{
  id : CONTENT_RATING.PG_13, header: 'Pg-13 content', message: 'This is PG-13 marked content, want to continue?'
}];
