import { apiV1 } from './config';

const getMovieList = (subType) => {
  const response = apiV1(false).get(`/content/lists?multipart=False&subtype=${subType || ""}`, {
    'axios-retry': {
      retries: 0
    }
  });
  return response;
}
const getSeriesList = () => {
  const response = apiV1(false).get(`/content/lists?multipart=True`, {
    'axios-retry': {
      retries: 0
    }
  });
  return response;
}
const getHomeCarouselList = () => {
    const response = apiV1(false).get(`/content/home`, {
      'axios-retry': {
        retries: 0
      }
    });
    console.log(response);
    return response;
}
const getSeriesInfo = (id) => {
  const response = apiV1(false).post(`/content/get/`,{id, multipart: true} );
  console.log(response);
  return response;
}
const getMovieInfo = (id) => {
  const response = apiV1(false).post(`/content/get/`,{id, multipart: false} );
  console.log(response);
  return response;
}
const getSearchMovieResult = (queryString, cursor) => {
  const response = apiV1(false).get(`/content/search?q=${queryString}&cursor=${cursor || ""}`, {
    'axios-retry': {
      retries: 0
    }
  });
  return response;
}
const getHistory = () => {
  const response = apiV1().get(`/history`, {
    'axios-retry': {
      retries: 0
    }
  });
  return response;
}
const getPaymentHistory = () => {
  const response = apiV1().get(`/payments`, {
    'axios-retry': {
      retries: 0
    }
  });
  return response;
}
const getMovieFeatured = () => {
  const response = apiV1(false).get(`/content/featured`, {
    'axios-retry': {
      retries: 0
    }
  } );
  console.log(response);
  return response;
}
const getVideoUrl = (id,isIos,isMobile,browserName) => {
  const response = apiV1(true).post(`/content/watch/`,{id,isIos,isMobile,browserName} );
  console.log(response);
  return response;
}
export {
  getMovieList,
  getSeriesList,
  getHomeCarouselList,
  getSearchMovieResult,
  getSeriesInfo,
  getMovieInfo,
  getHistory,
  getMovieFeatured,
  getPaymentHistory,
  getVideoUrl
}
