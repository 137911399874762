import React, { useState, useEffect } from "react";
import RippleEffect from "../Ripple/Ripple";
import "./EpisodeListMobile.scss";

const EpisodeListMobile = ({ series, onWatchClick, selectedSeason }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(series?.length || 0);
  }, [series]);

  return (
    <div>
      <div className="series-mobile">
        {series?.seasons[selectedSeason]?.items?.map((item, i) => (
          <RippleEffect key={`${selectedSeason}-${i}`}>
            <div className="episodeCard-mobile" onClick={() => onWatchClick(item?.id, item?.collection_id)}>
              <div className="episodeImg-mobile">
                <img src={item?.thumbnails?.[0]} alt="" />
              </div>
              <div className="episodeInfo-mobile">
                <h2 className="title-font">{item?.prefix || item?.title}</h2>
                <h2 className="description-font">{item?.description}</h2>
              </div>
            </div>
          </RippleEffect>
        ))}
      </div>
    </div>
  );
};

export default EpisodeListMobile;