import { IonItem, IonLabel } from "@ionic/react";
import { PageWrapperComponent } from "modules/common";
import React from "react";
import NotFoundSvg from "assets/img/notfound.svg";

import "./NotFound.scss";

const NotFound = () => (
  <PageWrapperComponent>
    <div className="notfound-wrap">
      <img src={NotFoundSvg} alt="Not Found" />
      <h1>
        Oops! We couldn’t find this page.
        <br /> you can choose from a varity of content from our homepage
      </h1>
      <IonItem
        button={true}
        lines="none"
        detail={false}
        routerLink={"/"}
        routerDirection="none"
      >
        <IonLabel>{"Go To Home"}</IonLabel>
      </IonItem>
    </div>
  </PageWrapperComponent>
);

export default NotFound;
