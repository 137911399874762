import React, { useState } from "react";
import "./Profile.scss";
import { useAnalytics } from "use-analytics";
import { FormattedMessage } from "react-intl";
import {
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonButton,
  IonLoading,
  IonModal,
  isPlatform,
} from "@ionic/react";
import { connect } from "react-redux";
import {
  cancelSubscription,
  getUserSubscriptionDetails,
  applyCoupon,
  verifyGooglePlayPurchase,
} from "actions/services/user.service";
import { ROUTES } from "helpers/routeHelper";
import { useHistory } from "react-router-dom";
import Avatar from "react-avatar";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2";
import { Capacitor } from "@capacitor/core";
import { setPageTitle } from "helpers/seoHelper";
import { PageWrapperComponent } from "modules/common";
import { PlansPaymentPopupComponent } from "modules/common";

const Profile = ({ fullName, email, userVerified, phoneNumber, userPlans }) => {
  const history = useHistory();
  const { page } = useAnalytics();

  const [showPlansPayment, setPlansPaymentShow] = useState(false);
  const [subsData, setSubsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [message, setMessage] = useState("");

  const [showUnsubscribeModel, setShowUnsubscribeModel] = useState(false);
  const [unsubscribeSteps, setUnsubscribeSteps] = useState([]);

  const onCancelSubscription = () => {
    cancelSubscription().then((res) => {
      const data = res?.data?.data;
      if (res?.data?.success) {
        if (data?.gateway === "razorpay") {
          alert("Your subscription has been cancelled successfully.");
        } else {
          setUnsubscribeSteps(data?.steps);
          setShowUnsubscribeModel(true);
        }
      }
    });
  };

  useIonViewWillEnter(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.USER_PROFILE);
    setPageTitle("USER_PROFILE");
    getUserSubscriptionDetails().then((res) => {
      if (res?.data?.success) {
        setSubsData(res?.data?.data);
      }
    });
  });

  useIonViewWillLeave(() => {
    setSubsData(null);
  });

  const submitCouponCode = () => {
    setIsLoading(true);
    setMessage("");
    applyCoupon(couponCode)
      .then((res) => {
        if (res?.data?.success) {
          setMessage("Successfully applied coupon");
          setIsLoading(false);
        } else {
          setMessage(res?.data?.error?.toString() || "");
        }
      })
      .catch((e) => {
        if (e?.response?.data?.error) {
          setMessage(e?.response?.data?.error || "");
        }
      });
  };

  const verifyPurchase = () => {
    try {
      if (Capacitor.isNativePlatform()) {
        InAppPurchase2.refresh();
        // eslint-disable-next-line no-unused-expressions
        userPlans?.forEach((plan) => {
          if (plan?.period) {
            InAppPurchase2.when(plan?.period).approved((product) => {
              verifyGooglePlayPurchase({
                transaction: product?.transaction,
                gateway: isPlatform("ios")
                  ? "appstore_billing"
                  : "playstore_billing",
              }).then((res) => {
                if (res?.data?.success) {
                  product.finish();
                  alert("Successfully verified");
                } else {
                  alert("Some Backend issue occurred");
                }
              });
            });
          }
        });
      }
    } catch (e) {}
  };

  return (
    <PageWrapperComponent>
      <IonLoading
        isOpen={isLoading}
        onDidDismiss={() => setIsLoading(false)}
        message={"Applying Coupon..."}
        duration={5000}
      />
      <div className="profileContainer">
        <div className="userProfile">
          <div className="userProfileHeader">
            <h3>
              <FormattedMessage id="profile.MyAccount" />
            </h3>
            <Avatar
              color={Avatar.getRandomColor("sitebase", ["red"])}
              size="70px"
              textSizeRatio={2}
              round={true}
              name={fullName}
            />
          </div>
          <div className="infoBlock">
            <div className="infoBlockRow">
              <div className="infoType">
                <FormattedMessage id="profile.FullName" />
              </div>
              <div className="infoValue">{fullName}</div>
            </div>
            <div className="infoBlockRow">
              <div className="infoType">
                <FormattedMessage id="profile.PhoneNumber" />
              </div>
              <div className="infoValue">{phoneNumber}</div>
            </div>

            <div className="infoBlockRow">
              <div className="infoType">
                <FormattedMessage id="profile.Email" />
              </div>
              <div className="infoValue">{email}</div>
            </div>

            {/* <div className="infoBlockRow">
                <div className="infoType">
                  <FormattedMessage id="profile.Password"/>
                </div>
                <div className="infoValue">**********</div>
              </div> */}

            {subsData?.name && (
              <div className="infoBlockRow">
                <div className="infoType">Subscription Type</div>
                <div className="infoValue">{subsData?.name}</div>
              </div>
            )}

            {subsData?.renewal_date && (
              <div className="infoBlockRow">
                <div className="infoType">Renewal Date</div>
                <div className="infoValue">
                  {new Date(subsData?.renewal_date).toDateString()}
                </div>
              </div>
            )}

            <div className="infoBlockRow">
              <div className="infoType">
                <FormattedMessage id="profile.Subscription" />
              </div>
              <div className="infoValue">
                {new Date(subsData?.end) > new Date() &&
                subsData?.mode != "coupon" ? (
                  <IonButton
                    className="btnprimary"
                    color="secondary"
                    size="small"
                    onClick={() => onCancelSubscription()}
                  >
                    Cancel Subscription
                  </IonButton>
                ) : (
                  <IonButton
                    className="btnprimary"
                    color="secondary"
                    size="small"
                    onClick={() => history.replace(ROUTES.WATCH_SUBSCRIBE)}
                  >
                    Buy Subscription
                  </IonButton>
                )}
              </div>
            </div>

            {Capacitor.isNativePlatform() &&
              !(
                new Date(subsData?.end) > new Date() &&
                subsData?.mode != "coupon"
              ) && (
                <div className="infoBlockRow">
                  <div className="infoType">
                    <FormattedMessage id="profile.VerifyPurchase" />
                  </div>
                  <div className="infoValue">
                    <IonButton
                      className="btnprimary"
                      color="secondary"
                      size="small"
                      onClick={() => verifyPurchase()}
                    >
                      Click To verify
                    </IonButton>
                  </div>
                </div>
              )}

            <div className="infoBlockRow">
              <div className="infoType">
                <FormattedMessage id="profile.ContactDetails" />
              </div>
              <div className="infoValue">
                {userVerified ? (
                  <span>Verified</span>
                ) : (
                  <IonButton
                    className="btnprimary"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setPlansPaymentShow(true);
                    }}
                  >
                    Click To verify
                  </IonButton>
                )}
              </div>
            </div>

            {!(Capacitor.isNativePlatform() && isPlatform("ios")) && (
              <div className="infoBlockRow">
                {/* <div className="infoType">
                  <FormattedMessage
                    id="profile.ContactDetails"
                    
                  />
                </div> */}
                <div className="infoType">
                  {subsData?.mode ? (
                    <div className="infoType">Subscription Mode:</div>
                  ) : (
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Coupon Code"
                        data-type="couponCode"
                        name="couponCode"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e?.target?.value)}
                      />
                    </div>
                  )}
                </div>
                <div className="infoValue">
                  {subsData?.mode ? (
                    <span>{subsData?.mode}</span>
                  ) : (
                    <>
                      <IonButton
                        className="btnprimary"
                        color="secondary"
                        size="small"
                        disabled={!couponCode}
                        onClick={() => {
                          submitCouponCode();
                        }}
                      >
                        Apply Coupon Code
                      </IonButton>
                      {message}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* <a href="">
              <FormattedMessage id="profile.ChangePassword" />
            </a> */}
        </div>
      </div>
      <PlansPaymentPopupComponent
        isVerificationOnly={true}
        setShow={(val) => {
          setPlansPaymentShow(val);
        }}
        show={showPlansPayment}
      />
      <IonModal isOpen={showUnsubscribeModel} cssClass="cancel-popup-wrap">
        <div className="cancel-popup-body">
          <div className="logoimg">
            <img
              src="https://flowgiga.ams3.cdn.digitaloceanspaces.com/demo_assets/logo_files/flowgiga_logo_big_with_text_landscape.png"
              alt="logo"
            />
          </div>
          <ul className="steps-wrapper">
            {unsubscribeSteps.map((s) => (
              <li>
                <span>Step </span> {s}
              </li>
            ))}
          </ul>
          <i className="note">
            Please note: Uninstalling the app will not cancel your subscription.
          </i>
        </div>
        <IonButton
          color="secondary"
          onClick={() => setShowUnsubscribeModel(false)}
        >
          Close
        </IonButton>
      </IonModal>
    </PageWrapperComponent>
  );
};

const mapStateToProps = ({ user }) => ({
  fullName: user?.fullName,
  email: user?.email,
  userVerified: user?.verified,
  phoneNumber: user?.phoneNumber,
  userPlans: user?.plans,
});

export default connect(mapStateToProps, null)(Profile);
