/* eslint-disable*/
import React, { useEffect, useRef } from "react";
import {
    setShowHeader,
    setShowSideMenu,
    setShowTabs,
    setDefault,
} from "store/slices/appSlice";
import { connect } from "react-redux";
import "./VideoJsPlayer.scss";
import { useAnalytics } from "use-analytics";

import { PLAYER_EVENT, TRACK_EVENT } from "analytics/analyticsHelper";
import { useHistory } from "react-router";
import Slider from "react-slick";
import { PageWrapperComponent } from "modules/common";
import { CastCardMobileComponent } from "modules/common";
// import "videojs-ima-player";
// import "videojs-ima-player/dist/videojs.ima.css";
import videojs from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';

import 'videojs-ima/dist/videojs.ima.scss';
import { getPlatforms } from "@ionic/react";



const VideoJsPlayer = ({
    url,
    movieInfo,
    setShowHeaderAction,
    setShowSideMenuAction,
    setShowTabsAction,
    setDefaultAction,
    isMobile,
    sessionId,
}) => {
    const history = useHistory();
    const { track, page, identify } = useAnalytics();
    const playerRef = useRef(null);
    const trackData = useRef({
        sessionId: sessionId,
        MovieId: movieInfo?.item?.id,
        SeasonId: movieInfo?.item?.season_id,
        CollectionId: movieInfo?.collection?.id,
        Title: movieInfo?.collection?.title,
        Type: movieInfo?.collection?.multipart ? 'Series' : 'Movie',
        Player: 'VideoJsPlayer.JS'
      });
    // let volumeRange = document.getElementById('volumebar');
    // let seekbar = document.getElementById('seekbar');
    useEffect(() => {
        page(TRACK_EVENT.APP_USAGE.SCREEN.PLAYER_IOS);
        setShowHeaderAction(false);
        setShowSideMenuAction(false);
        setShowTabsAction(false);
        if (!url) {
            history.push("/");
        } else {
            let video = document.getElementById('content_video');
            // ads skippable ads support to IOS
            videojs.browser.IS_IOS ? video.setAttribute('playsinline', '') : '';
            let isMobile = videojs.browser.IS_IOS || videojs.browser.IS_ANDROID;
            let player = videojs('content_video', {
                muted: video?.muted || (video?.autoplay && isMobile),
            });

            playerRef.current = player;
            if (player.ima) {
                player.ima({
                    debug: true,
                    adTagUrl: 'http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&' +
                        'iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&' +
                        'impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&' +
                        'cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&' +
                        'vid=short_onecue&correlator='
                });
            }
        }
        return () => {
            try {
                playerRef.current.dispose();
                track(
                    TRACK_EVENT.PLAYER_EVENT.NAME,
                    TRACK_EVENT.PLAYER_EVENT.getProps(
                      TRACK_EVENT.PLAYER_EVENT.ACTION.EXIT,
                      {...trackData.current}
                    )
                  );
            } catch (e) { }
            setDefaultAction();
        };
    }, []);

    useEffect(() => {
        let videoElement = document.getElementById("content_video");
        let player = videoElement?.player;
        var data = {
          url: url,
          Platforms: getPlatforms().join(","),
        }
        player.on('loadedmetadata', function (event) {
    
            track(
              TRACK_EVENT.PLAYER_EVENT.NAME,
              TRACK_EVENT.PLAYER_EVENT.getProps(
                TRACK_EVENT.PLAYER_EVENT.ACTION.INITILIZED,
                { 
                    ...data , ...trackData.current }
              )
            );
          })
          player.on('ended', function (event) {
    
          track(
            TRACK_EVENT.PLAYER_EVENT.NAME,
            TRACK_EVENT.PLAYER_EVENT.getProps(
              TRACK_EVENT.PLAYER_EVENT.ACTION.ENDED,
              { currentTime: videoElement.player.currentTime()
                , ...data, ...trackData.current  }
            )
          );
        });
        player.on('seeked', function (event) {

          track(
            TRACK_EVENT.PLAYER_EVENT.NAME,
            TRACK_EVENT.PLAYER_EVENT.getProps(
              TRACK_EVENT.PLAYER_EVENT.ACTION.SEEK,
              { ...data, currentTime: videoElement.player.currentTime()
                , ...trackData.current  }
            )
          );
        });
        player.on('play', function (event) {

          track(
            TRACK_EVENT.PLAYER_EVENT.NAME,
            TRACK_EVENT.PLAYER_EVENT.getProps(
              TRACK_EVENT.PLAYER_EVENT.ACTION.PLAYED,
              { ...data, currentTime: videoElement.player.currentTime()
                , ...trackData.current  }
            )
          );
        });
        player.on('pause', function (event) {

          track(
            TRACK_EVENT.PLAYER_EVENT.NAME,
            TRACK_EVENT.PLAYER_EVENT.getProps(
              TRACK_EVENT.PLAYER_EVENT.ACTION.PAUSED,
              { ...data, currentTime: videoElement.player.currentTime()
                , ...trackData.current  }
            )
          );
        });
      }, []);

    const settings = {
        lazyLoad: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <>
            <PageWrapperComponent hideHeader hideFooter pageClass="video-player">
                <div className="dash-video-player-safari">
                    {/* <!-- HTML structure needed for the ControlBar --> */}

                    <div className="videoContainerSafari" id="videoContainerSafari">
                        <div className="videocontainerinner">


                            <video
                                className="videoElement video-js vjs-default-skin vjs-big-play-centered"
                                id="content_video"
                                controls
                                preload="auto"
                                muted
                                autoplay
                            >
                                <source src={'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'}
                                // type="application/x-mpegURL" 
                                />
                            </video>

                        </div>
                    </div>
                </div>

                {movieInfo && isMobile && (
                    <div>
                        <div></div>
                        <div className="movieInfoContainer">
                            <div className="font-bold">
                                <div className="titleBlock">
                                    <div>
                                        <h2 className="title">{movieInfo?.collection?.title}</h2>
                                        <ul className="movie-details-wrap">
                                            <li className="movie-details-list">
                                                {movieInfo?.item?.duration}
                                            </li>
                                            <li className="movie-details-list">
                                                {movieInfo?.collection?.rating}
                                            </li>
                                            <li className="movie-details-list">English</li>
                                            <li className="movie-details-list">
                                                {movieInfo?.collection?.genre}
                                            </li>
                                            <li className="movie-details-list">
                                                {movieInfo?.item?.rating_tags}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="descriptionBlock">
                                    <p>{movieInfo?.collection?.description}</p>
                                    <div className="cast-card-wrap">
                                        <h5 className="cast-title">Cast:</h5>
                                        <div className="cast-card-slider-wrap">
                                            <Slider {...settings}>
                                                {movieInfo?.artists?.map((actor, key) => (
                                                    <CastCardMobileComponent key={key} actor={actor} />
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </PageWrapperComponent>
        </>
    );
};

const mapStateToProps = ({ payment, app }) => ({
    isMobile: app?.isMobile,
    url: payment?.playerUrl?.url,
    movieInfo: payment?.movieInfo,
    sessionId: payment?.playerUrl?.sessionId,
});

const mapDispatchToProps = {
    setShowHeaderAction: setShowHeader,
    setShowSideMenuAction: setShowSideMenu,
    setShowTabsAction: setShowTabs,
    setDefaultAction: setDefault,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoJsPlayer);
