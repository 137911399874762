import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { ROUTES } from "helpers/routeHelper";

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    duration: PropTypes.string,
    rating: PropTypes.string,
    genre: PropTypes.array,
    times: PropTypes.number,
    banners: PropTypes.array,
  }),
};

const HistoryItem = ({ item }) => {
  const history = useHistory();

  const url = useMemo(() => {
    return item?.multipart
      ? ROUTES.SERIES_INFO.replace(":movieId", item?.id)
      : ROUTES.MOVIE_INFO.replace(":movieId", item?.id);
  });

  return (
    <div className="watchHistoryListItem">
      <ion-card>
        <div className="card-inside-wrap">
          <div className="img-wrapper">
            <img className="historyimg" src={item?.banners[0]} />
          </div>
          <div className="card-content">
            <ion-card-header>
              <ion-card-subtitle>{item?.title}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <div className="moviedetail">
                {item?.duration} &nbsp; {item?.rating} &nbsp; {item?.genre}
              </div>
              {/* <div className="lastseen">
                <FormattedMessage id="history.lastSeen" />: {item?.date}
              </div>
              <div className="seencount">
                <FormattedMessage id="history.timesSeen" />: {item?.times}
              </div> */}
              <div className="watchHistoryListItemBtnGroup">
                <button
                  onClick={() => {
                    history.push(url);
                  }}
                >
                  <FormattedMessage id="history.watchAgain" />
                </button>
                {/* <button
                  onClick={() => {
                    history.replace(url);
                  }}
                >
                  <FormattedMessage id="history.continue" />
                </button> */}
              </div>
            </ion-card-content>
          </div>
        </div>
      </ion-card>
    </div>
  );
};

HistoryItem.propTypes = propTypes;

export default HistoryItem;
