import axios from "axios";
import axiosRetry from "axios-retry";
import { getBackendApiUrl } from "../../config/config";
import localStore from "../../utils/localStore";
import { toast } from "react-toastify";

const apiV1 = (withAuth = true, version = "V1") => {
  // authenticate user if jwt token is in localstore
  const http = axios.create({
    baseURL: getBackendApiUrl()
  });
  if (localStore.getToken() && withAuth) {
    http.defaults.headers.common.Authorization = `JWT ${localStore.getToken()}`;

    // return axios.create({
    //   baseURL: getBackendApiUrl(),
    //   headers: {
    //     Authorization: `JWT ${localStore.getToken()}`
    //   }
    // });
  }
  // Add a request interceptor
  http.interceptors.request.use(
    config => /* Do something before request is sent */ config,
    error => Promise.reject(error) // Do something with request error
  );

  // Add a response interceptor
  http.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    error => {
      const option = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (!error?.status && !error?.response?.status) {
        // network error
        toast.warning("There is some unhandled error has occurred, maybe API is down or Network is not available.", option );
        // alert(
        //   "There is some unhandled error has occured, maybe API is down or Network is not available."
        // );
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("userName");
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        if (!window.location.href.includes("/one/auth")) {
          window.location.href = "/one/auth";
        }
      }
      if (error?.response?.status === 500) {
        toast.error('Some API error occurred', option ); 
      }
      return Promise.reject(error);
    }
  );
  // return http;
  axiosRetry(http, {
    retries: 3,
    retryDelay: retryCount => retryCount * 1000
  });
  return http;
  // return axios.create({
  //   baseURL: getBackendApiUrl()
  // });
};

export { apiV1 };
