import React from "react";
import { connect } from "react-redux";
import { IonPage, IonContent, IonMenuButton } from "@ionic/react";
import { HeaderComponent } from "modules/common";
import { FooterComponent } from "modules/common";
import { IonIcon } from "@ionic/react";
import {
  homeOutline,
  personOutline,
  tvOutline,
  filmOutline,
  trophyOutline,
  menu,
  menuOutline,
  gameController,
} from "ionicons/icons";
import { useHistory, useRouteMatch } from "react-router";
import { FormattedMessage } from "react-intl";
import { ROUTES } from "helpers/routeHelper";
import "./PageWrapper.scss";
import { SHOP_URL } from "clientConfig";
import { SHOW_TABS } from "clientConfig";
import { GAME_URL } from "clientConfig";

const PageWrapper = ({
  children,
  hideHeader,
  hideFooter,
  pageClass,
  contentEl,
  showTabs,
  isMobile,
  isAuthUser,
}) => {
  const { path } = useRouteMatch();

  const hide = isMobile && (path.includes('movie') || path.includes('series'));

  const getTitle = () => {
    if(path.includes('movielist') || path.includes('movieinfo')) {
      return "Movies";
    } else if(path.includes('serieslist') || path.includes('seriesinfo')) {
      return "Series";
    }
  }

  return (
    <>
      <Tabs
        isAuthUser={isAuthUser}
        isWebMobile={isMobile && !Capacitor.isNativePlatform()}
        showTabs={showTabs}
        path={path}
      >
        <IonPage className={`ionPage ${pageClass}`}>
          {!hideHeader && (
            <HeaderComponent
              hideSearch={hide}
              pageTitle={getTitle()}
              hideMenuButton={hide}
              hideLogo={hide}
              showBack={true}
            />
          )}
          <IonContent
            ref={contentEl}
            id="main-content"
            className="main-body has-header"
            scrollX="true"
            scrollY="true"
            fullscreen="false"
            scrollEvents={true}
          >
            <div className="children-wrapper">{children}</div>
            {!hideFooter && <FooterComponent />}
          </IonContent>
        </IonPage>
      </Tabs>
    </>
  );
};

const mapStateToProps = ({ user, app }) => ({
  showTabs: app?.showTabs,
  isMobile: app?.isMobile,
  isAuthUser: user?.isAuthUser,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper);

const Tabs = ({ children, showTabs, isWebMobile, isAuthUser, path }) => {
  const history = useHistory();
  if (!showTabs || !isWebMobile) {
    return <>{children}</>;
  }
  return (
    <>
      {children}
      <div className="tabs-wrapper">
        {SHOW_TABS.HOME && (
          <div
            className={`ion-icon-button d-flex flex-column align-items-center ${
              path == ROUTES.HOME ? "active" : ""
            }`}
            onClick={() => history.push(ROUTES.HOME)}
          >
            <IonIcon icon={homeOutline} />
            <div className="tabs-label">
              <FormattedMessage id="tab.Home" />
            </div>
          </div>
        )}
        {SHOW_TABS.MOVIES_LIST && (
          <div
            className={`ion-icon-button d-flex flex-column align-items-center ${
              path == ROUTES.SERIES_LIST ? "active" : ""
            }`}
            onClick={() => history.push(ROUTES.MOVIES)}
          >
            <IonIcon icon={filmOutline} />
            <div className="tabs-label">
              <FormattedMessage id="tab.Movies" />
            </div>
          </div>
        )}
        {SHOW_TABS.SERIES_LIST && (
          <div
            className={`ion-icon-button d-flex flex-column align-items-center ${
              path == ROUTES.SERIES_LIST ? "active" : ""
            }`}
            onClick={() => history.push(ROUTES.SERIES_LIST)}
          >
            <IonIcon icon={tvOutline} />
            <div className="tabs-label">
              <FormattedMessage id="tab.Webseries" />
            </div>
          </div>
        )}
        {/* {SHOW_TABS.EXPLORE && (
          <div
            className={`ion-icon-button d-flex flex-column align-items-center ${
              path == ROUTES.EXPLORE ? "active" : ""
            }`}
            onClick={() => history.push(ROUTES.EXPLORE)}
          >
            <IonIcon icon={tvOutline} />
            <div className="tabs-label">
              <FormattedMessage id="tab.Explore" />
            </div>
          </div>
        )} */}
        {SHOW_TABS.COINS && (
          <div
            className={`ion-icon-button d-flex flex-column align-items-center ${
              path == ROUTES.COINS ? "active" : ""
            }`}
            onClick={() => history.push(ROUTES.COINS)}
          >
            <IonIcon icon={trophyOutline} />
            <div className="tabs-label">
              <FormattedMessage id="tab.Rewards" />
            </div>
          </div>
        )}
        {SHOW_TABS.SHOP && (
          <div
            className={`ion-icon-button d-flex flex-column align-items-center ${
              path == ROUTES.SHOP ? "active" : ""
            }`}
            onClick={() => window.open(SHOP_URL, "_blank")}
          >
            <IonIcon icon={gameController} />
            <div className="tabs-label">
              <FormattedMessage id="tab.Shop" />
            </div>
          </div>
        )}
        {SHOW_TABS.GAME && (
          <div
            className={`ion-icon-button d-flex flex-column align-items-center ${
              path == ROUTES.SHOP ? "active" : ""
            }`}
            onClick={() => window.open(GAME_URL, "_blank")}
          >
            <IonIcon icon={gameController} />
            <div className="tabs-label">
              <FormattedMessage id="tab.Games" />
            </div>
          </div>
        )}
        {/* {SHOW_TABS.ACCOUNT && (
          <div
            className={`ion-icon-button d-flex flex-column align-items-center ${
              path == ROUTES.USER_PROFILE ? "active" : ""
            }`}
            onClick={() =>
              history.push(isAuthUser ? ROUTES.USER_PROFILE : ROUTES.AUTH)
            }
          >
            <IonIcon icon={personOutline} />
            <div className="tabs-label">
              <FormattedMessage id="tab.Account" />
            </div>
          </div>
        )} */}
        {/* <div
          className={`ion-icon-button d-flex flex-column align-items-center`}
        >
          <IonMenuButton color="primary">
            <IonIcon icon={menuOutline} />
          </IonMenuButton>

          <div className="tabs-label">Menu</div>
        </div> */}
      </div>
    </>
  );
};
