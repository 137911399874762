import React, { useState, useEffect, useMemo } from "react";
import "./AuthPageMobile.scss";
import { IonButton, IonIcon, isPlatform } from "@ionic/react";
import { logoGoogle, logoFacebook } from "ionicons/icons";
import countryCodes from "country-codes-list";
import Select from "react-select";
import { Link } from "react-router-dom";
import { BUSINESS_CONFIG, LOGO, SHOW_SOCIAL_AUTH } from "../../../../clientConfig";
import { ROUTES } from "helpers/routeHelper";
import { Capacitor } from "@capacitor/core";

const AuthenticationPageMobile = ({
  signIn,
  handleChange,
  user,
  forgetPassword,
  resetForm,
  signUp,
  error,
  resetError,
  errorMessage,
  successMessage,
  isForgetPasswordClicked,
  setMessage,
  setUser,
  setIsForgetPasswordClicked
}) => {
  const [authToggle, setAuthToggle] = useState(false);
  const [country, setCountry] = useState(null);
  const countryOptions = useMemo(
    () =>
      countryCodes.all().map(x => ({
        value: x.countryCallingCode,
        label: `${x.countryNameEn}`
      })),
    []
  );

  return (
    <div className="authPage-mobile">
      <div className="signInHeader-mobile">
        <Link to={ROUTES.HOME} routerDirection="back">
          <img
            src={LOGO}
            alt=""
          />
        </Link>
        <div className="authBtnGroup-mobile">
          <IonButton
            size="small"
            color={authToggle ? "light" : "primary"}
            className={authToggle ? "authBtn-mobile" : "authBtn-active-mobile"}
            onClick={() => {
              setAuthToggle(false);
              setMessage("");
              resetForm(true);
              resetError();
              setIsForgetPasswordClicked(false);
            }}
          >
            Sign In
          </IonButton>

          <IonButton
            size="small"
            color={authToggle ? "primary" : "light"}
            className={authToggle ? "authBtn-active-mobile" : "authBtn-mobile"}
            onClick={() => {
              setAuthToggle(true);
              setMessage("");
              resetForm(false);
              resetError();
              setIsForgetPasswordClicked(false);
            }}
          >
            Sign Up
          </IonButton>
        </div>
      </div>
      <div className="authForm-mobile">
        {authToggle ? (
          <>
            <div className="input-group">
              <input
                className="input-control"
                type="text"
                placeholder="Display Name"
                name="fullName"
                value={user.fullName}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.fullName}</span>
            </div>
            <div className="input-group">
              <input
                className="input-control"
                type="text"
                placeholder="Email"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.email}</span>
            </div>
            {/* <div className="input-group">
              <div className="numbercode-wrap">
                <Select
                  isSearchable={true}
                  className="countrycode"
                  placeholder="Country Code"
                  value={country}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      countryCode: e?.value,
                    });
                    setCountry(e);
                  }}
                  name="countryCode"
                  options={countryOptions}
                />
                <input
                  className="input-control"
                  type="text"
                  placeholder="Phone number"
                  name="phone"
                  value={user.phone}
                  onChange={handleChange}
                />
                <span className="error text-danger">{error?.countryCode}</span>
                <span className="error text-danger">{error?.phone}</span>
              </div>
            </div> */}
            <div className="input-group">
              <input
                className="input-control"
                type="password"
                placeholder="Password"
                data-type="password"
                name="password"
                value={user.password}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.password}</span>
            </div>
            <div className="input-group">
              <input
                className="input-control"
                type="password"
                placeholder="Confirm Password"
                data-type="password"
                name="repeatPassword"
                value={user.repeatPassword}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.repeatPassword}</span>
            </div>
            {/* { !(Capacitor.isNativePlatform() && isPlatform("ios")) &&
            <div className="input-group">
              <input
                className="input-control"
                type="text"
                placeholder="Coupon Code"
                data-type="couponCode"
                name="couponCode"
                value={user.couponCode}
                onChange={handleChange}
              />
            </div>
            } */}
            <div className="input-group">
              <label className="privacypolicy">
                <input
                  type="checkbox"
                  name="acceptPrivacyPolicy"
                  value={user.acceptPrivacyPolicy}
                  onChange={handleChange}
                />
                <a target="_blank" href={BUSINESS_CONFIG.PrivacyPolicy}>
                  I have read the privacy policy & terms of use and agree to the
                  same
                </a>
              </label>
              <span className="error text-danger">
                {error?.acceptPrivacyPolicy}
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="input-group">
              <input
                className="input-control"
                type="text"
                placeholder="Email/Phone Number(with country code)"
                id="user"
                name="email"
                readOnly={isForgetPasswordClicked}
                value={user.email}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.email}</span>
            </div>
            <div className="input-group">
              <input
                className="input-control"
                type="password"
                placeholder="Password"
                id="pass"
                data-type="password"
                name="password"
                value={user.password}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.password}</span>
            </div>
            <div className="input-group">
              {isForgetPasswordClicked && (
                <>
                  <div className="input-group">
                    <input
                      className="input-control"
                      type="password"
                      placeholder="Confirm Password"
                      data-type="password"
                      name="repeatPassword"
                      value={user.repeatPassword}
                      onChange={handleChange}
                    />
                    <span className="error text-danger">
                      {error?.repeatPassword}
                    </span>
                  </div>
                  <div className="input-group">
                    <input
                      className="input-control"
                      type="text"
                      placeholder="Passcode"
                      id="passcode"
                      autoComplete="otp"
                      name="passcode"
                      value={user.passcode}
                      onChange={handleChange}
                    />
                    <span className="error text-danger">{error?.passcode}</span>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {errorMessage && (
          <span className="error text-danger">{errorMessage}</span>
        )}
        {successMessage && (
          <span className="text text-success">{successMessage}</span>
        )}
        {authToggle ? (
          // <button onClick={signUp}>Sign Up</button>
          <IonButton onClick={signUp} className="signbtn" color="primary">
            Sign Up
          </IonButton>
        ) : isForgetPasswordClicked ? (
          <IonButton
            onClick={() => forgetPassword(true)}
            className="signbtn"
            color="secondary"
          >
            Submit
          </IonButton>
        ) : (
          <IonButton onClick={signIn} className="signbtn" color="primary">
            Sign In
          </IonButton>
        )}
        {SHOW_SOCIAL_AUTH && (
          <div className="signWithSocial-mobile">
            <IonButton className="socialbtn" color="white">
              Signin with <IonIcon className="socialicon" icon={logoGoogle} />
              oogle{" "}
            </IonButton>
            <IonButton className="socialbtn" color="white">
              Signin with <IonIcon className="socialicon" icon={logoFacebook} />
              facebook{" "}
            </IonButton>
          </div>
        )}
        {authToggle ? (
          <a href="" className="forgotPassword-mobile">
            Already a member?
          </a>
        ) : (
          <IonButton className="socialbtn" fill="clear">
            <span
              onClick={() => {
                forgetPassword();
              }}
              href=""
              className="forgotPassword"
            >
              forgot your password?
            </span>
          </IonButton>
        )}
      </div>
    </div>
  );
};

export default AuthenticationPageMobile;
