import React from "react";
import { Link } from "react-router-dom";
import { menuController } from "@ionic/core";
import Avatar from "react-avatar";
import "./sideMenu.scss";
import {
  IonContent,
  IonItem,
  IonList,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonLabel,
  IonIcon,
} from "@ionic/react";

import { homeOutline, settingsOutline, timeOutline } from "ionicons/icons";

import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { saveUserDetails } from "../../../../store/slices/userSlice";
import localStore from "../../../../utils/localStore";
import { ROUTES } from "helpers/routeHelper";

const SideMenu = ({
  isAuthUser,
  user,
  email,
  fullName,
  disabled,
  saveUserDetailsAction,
}) => {
  const onLogout = () => {
    localStore.deauthenticateUser();
    window.location.reload();
    saveUserDetailsAction(null);
  };

  return (
    <>
      <IonMenu
        side="start"
        contentId="main-content"
        type="overlay"
        menuId="app-sidemenu"
        disabled={disabled}
      >
        <IonHeader>
          <IonToolbar color="primary">
            <div className="tollbar-header">
              <Avatar
                color={Avatar.getRandomColor("sitebase", [
                  "secondary",
                  "green",
                  "black",
                ])}
                size="60px"
                round={true}
                name={isAuthUser ? fullName : "Guest"}
              />
              <div className="nameBlock">
                <h3>{isAuthUser ? fullName : "Guest"} </h3>
                {isAuthUser && <h4 id="mail">{email || user?.phoneNumber}</h4>}
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList lines={"none"}>
            <div className="sideMenuNav">
              <div className="sideMenuNavFirstBlock">
                <IonItem
                  href={ROUTES.HOME}
                  onClick={async () => await menuController.toggle()}
                >
                  <div className="sideMenuNavItem">
                    <IonIcon icon={homeOutline} />
                    <IonLabel className="sideMenuLabel">
                      <FormattedMessage id="sideMenu.Home" />
                    </IonLabel>
                  </div>
                </IonItem>
                <IonItem
                  href={ROUTES.WATCH_HISTORY}
                  onClick={async () => await menuController.toggle()}
                >
                  <div className="sideMenuNavItem">
                    <IonIcon icon={timeOutline} />
                    <IonLabel className="sideMenuLabel">
                      <FormattedMessage id="sideMenu.WatchHistory" />
                    </IonLabel>
                  </div>
                </IonItem>
                <IonItem
                  href={ROUTES.SETTINGS}
                  onClick={async () => await menuController.toggle()}
                >
                  <div className="sideMenuNavItem">
                    <IonIcon icon={settingsOutline} />
                    <IonLabel className="sideMenuLabel">
                      <FormattedMessage id="sideMenu.AppSetting" />
                    </IonLabel>
                  </div>
                </IonItem>
              </div>
              <div className="sideMenuNavSecondBlock">
                {isAuthUser ? (
                  <IonItem
                    onClick={async () => {
                      onLogout();
                      await menuController.toggle();
                    }}
                  >
                    <IonLabel className="sideMenuSmallLabel">
                      <FormattedMessage id="sideMenu.LogOut" />
                    </IonLabel>
                  </IonItem>
                ) : (
                  <IonItem
                    href={ROUTES.AUTH}
                    onClick={async () => await menuController.toggle()}
                  >
                    <IonLabel className="sideMenuSmallLabel">
                      <FormattedMessage id="sideMenu.LogIn" />
                    </IonLabel>
                  </IonItem>
                )}
              </div>
            </div>
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
};

const mapStateToProps = ({ user, app }) => ({
  email: user?.email,
  user: user,
  userId: user?.userId,
  fullName: user?.fullName,
  isAuthUser: user?.isAuthUser,
});
const mapDispatchToProps = {
  saveUserDetailsAction: saveUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
