import React, { useEffect, useState, useMemo } from "react";
import Slider from "react-slick";
import { isPlatform, IonSkeletonText, IonThumbnail } from "@ionic/react";
import MovieCardNormal from "../MovieCard/Normal/MovieCardNormal";
import MovieCardShort from "../MovieCard/Short/MovieCardShort";
import MovieCardLongMobile from "../MovieCard/Long/MovieCardLongMobile/MovieCardLongMobile";
import MovieCardLongWeb from "../MovieCard/Long/MovieCardLongWeb/MovieCardLongWeb";
import "./MovieCarousel.scss";
import MovieCardOne from "../MovieCard/MovieCardOne/MovieCardOne";

const MovieCarousel = ({
  moviesList,
  name,
  isBanner,
  screen,
  showSkeleton,
  showVideo,
}) => {
  const [movies, setMovies] = useState(null);
  const [skeleton, setSkeleton] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setSkeleton(showSkeleton);
  }, [showSkeleton]);

  useEffect(() => {
    if(moviesList?.length) {
      setMovies(moviesList);
      setCount(moviesList?.length || 0);
    }
  }, [skeleton, moviesList, setMovies]);

  const isMobile = isPlatform("mobile");

  const normal = useMemo(() => {
    return isMobile
      ? movies?.map((movie, i) =>
          skeleton?.short ? (
            <IonThumbnail key={`movie-short-skeleton-${i}`} className="skeleton-card-short">
              <IonSkeletonText animated />
            </IonThumbnail>
          ) : (
            <MovieCardShort key={`movie-carousel-movie-short-${i}${movie?.id}`} movie={movie} screen={screen} />
          )
        )
      : movies?.map((movie, i) =>
          skeleton?.normal ? (
            <IonThumbnail className="skeleton-card-normal" key={i}>
              <IonSkeletonText animated />
            </IonThumbnail>
          ) : (
            <MovieCardNormal key={`movie-carousel-movie-short-${i}${movie?.id}`} movie={movie} screen={screen} />
            // <MovieCardOne key={`movie-carousel-movie-${i}${movie?.id}`} movie={movie} screen={screen} />
          )
        );
  }, [movies, skeleton, name]);

  const long = useMemo(() => {
    return isMobile
      ? movies?.map((movie, i) =>
          skeleton?.long ? (
            <IonThumbnail key={`mobile-carousel-skeleton-${i}`} className="skeleton-card-long-mobile">
              <IonSkeletonText animated />
            </IonThumbnail>
          ) : (
            <MovieCardLongMobile key={`mobile-carousel-movie-${i}`} collection={movie} screen={screen} showVideo={showVideo}/>
          )
        )
      : movies?.map((movie, i) =>
          skeleton?.long ? (
            <IonThumbnail key={`web-carousel-skeleton-${i}`} className="skeleton-card-long">
              <IonSkeletonText animated />
            </IonThumbnail>
          ) : (
            <MovieCardLongWeb key={`web-carousel-movie-${i}`} movie={movie} screen={screen} showVideo={showVideo}/>
          )
        );
  }, [movies, skeleton]);

  const settingsLong = {
    centerMode: true,
    dots: false,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3500,
    autoplay: false,
    swipeToSlide: true,
    centerPadding: "370px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 770,
        settings: {
          centerMode: false,
          centerPadding: "100px",
        },
      },
    ],
  };
  const BannerCarousel = () => <Slider {...settingsLong}>{long}</Slider>;

  const settings = useMemo(() => {
    return {
      lazyLoad: true,
      dots: false,
      infinite: count > 5,
      speed: 500,
      swipeToSlide: true,
      slidesToShow: 5,
      slidesToScroll: 2,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 5,
            infinite: count > 5,
            slidesToScroll: 2,
            dots: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
            infinite: count > 4,
            slidesToScroll: 1,
            dots: false,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            infinite: count > 4,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3.2,
            infinite: count > 3,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.55,
            infinite: false,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.55,
            infinite: false,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }, [count]);

  const CardCarousel = () => <Slider {...settings}>{normal}</Slider>;

  return (
    <>
      <>
        {isBanner ? (
          <div className="mb-2">
            <div className="owl-long border-bottom-big">
              {skeleton?.long && <BannerCarousel />}
              {!skeleton?.long && <BannerCarousel />}
            </div>
          </div>
        ) : (
          <>
            <div className="container-fluid carousal-container">
                <span className="">
                  {showSkeleton?.normal || showSkeleton?.short ? (
                    <IonSkeletonText animated style={{ width: "40%" }} />
                  ) : (
                    <h2 className="carosuel-icon">{name}</h2>
                  )}
                </span>
              <div className={`owl-${isMobile ? "short" : "normal"}`}>
                {(skeleton?.short || skeleton?.long) && <CardCarousel />}
                {!(skeleton?.short || skeleton?.long) && <CardCarousel />}
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};
export default MovieCarousel;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
