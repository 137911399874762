import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import "./MovieInfo.scss";
import {
  isPlatform,
  useIonAlert,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";

import { useAnalytics } from "use-analytics";
import { IonLoading, IonButton } from "@ionic/react";
import { getMovieInfo, getVideoUrl } from "actions/services";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { ROUTES } from "helpers/routeHelper";
import Slider from "react-slick";
import {
  saveFromApp,
  setPlayerUrl,
} from "store/slices/paymentSlice";
import { browserName, isMobileSafari, isSafari } from "react-device-detect";
import { getFeaturedMovie } from "store/slices/persistSlice/persistSlice";
import { setPageTitle } from "helpers/seoHelper";
import { PageWrapperComponent } from "modules/common";
import { CastCardMobileComponent } from "modules/common";
import { CastCardWebComponent } from "modules/common";
import { MovieCarouselComponent } from "modules/common";
import { MovieCardLongMobileComponent } from "modules/common";
import { CONTENT_WATCH_WARNING } from "clientConfig";

const MovieInfoMobile = ({
  setPlayerUrlAction,
  getFeaturedMovieAction,
  featuredMovieList,
}) => {
  const history = useHistory();
  const [present] = useIonAlert();
  const params = useParams();
  const [movie, setMovie] = useState(null);
  const [movieId, setMovieId] = useState(null);
  const isMobile = isPlatform("mobile");
  const iosWeb = isPlatform('ios') && isPlatform('mobileweb');
  const { track, page, identify } = useAnalytics();
  const [featuredMovies, setFeaturedMovies] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const contentEl = useRef(null);

  useIonViewWillEnter(() => {
    setPageTitle('MOVIE_INFO', movie?.collection?.title);
    page(TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO);
  });

  useIonViewWillLeave(() => {
    setMovie(null);
    setMovieId(null);
    console.log("ionViewWillLeave event fired: web info");
    var videoElement = document.getElementById("trailer-video");
    if (videoElement) {
      videoElement.pause();
      videoElement.removeAttribute("src"); // empty source
      videoElement.load();
    }
  });

  useIonViewDidEnter(() => {
    getFeaturedMovieAction();
  });

  useEffect(() => {
    if (featuredMovieList) {
      setFeaturedMovies(featuredMovieList);
    }
  }, [featuredMovieList]);

  const fetch = () => {
    const id = params?.movieId;
    if (id && id !== movieId) {
      contentEl?.current?.scrollToTop()
      setShowLoading(true);
      setMovieId(id);
      getMovieInfo(id)
        .then((res) => {
          if (res?.data?.success && res?.data?.data) {
            setMovie(res?.data?.data);
            setPageTitle('MOVIE_INFO', res?.data?.data?.collection?.title);
          }
        })
        .catch((err) => {})
        .finally(() => setShowLoading(false));
    }
    setPlayerUrlAction({}, null);
  };

  useEffect(() => {
    fetch();
  }, [params]);

  const settings = {
    lazyLoad: true,
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleContentWarning = (movieId, collectionId) => {
    const warning = CONTENT_WATCH_WARNING.find(w => w.id ===
      movie?.collection?.rating);
    if (warning) {
      present({
        cssClass: "my-css",
        header: warning.header,
        message: warning.message,
        buttons: [
          "No",
          { text: "Yes", handler: d => { onWatchClick(movieId, collectionId) } }
        ],
        onDidDismiss: e => { }
      });
    } else {
      onWatchClick(movieId, collectionId);
    }
  }

  const onWatchClick = (movieId, collectionId) => {
    getVideoUrl(
      movieId,
      isPlatform("ios"),
      isPlatform("mobileweb"),
      browserName
    )
      .then((res) => {
        const data = res?.data?.data;
        if (res?.data?.success && data?.url && data.status === "verified") {
          setPlayerUrlAction({ url: data?.url, movieId: movieId }, movie);
          history.push(ROUTES.PLAYER);
        } else {
          history.push(
            ROUTES.WATCH.replace(":movieId", movieId).replace(
              ":collectionId",
              collectionId
            )
          );
        }
      })
      .catch((err) => {
        if (err?.response?.status === 402) {
          history.replace(
            ROUTES.WATCH.replace(":movieId", movieId).replace(
              ":collectionId",
              collectionId
            )
          );
        }
      })
      .finally();
    track(
      TRACK_EVENT.APP_USAGE.NAME,
      TRACK_EVENT.APP_USAGE.getClickProps(
        TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.WATCH_NOW,
        TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO,
        {
          MovieId: movieId,
          CollectionId: collectionId,
          Title: movie?.collection?.title,
          Type: movie?.collection?.multipart ? 'Series' : 'Movie'
        }
      )
    );
  };

  const showWatchNowButton = () => {
    return movie?.collection?.release_state === "released";
  };

  return (
    <PageWrapperComponent contentEl={contentEl}>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
          duration={5000}
        />
        {movie && (
          <div>
            <div>
              <MovieCardLongMobileComponent
                key={`mobile-movie-card-long${movie?.collection?.id}`}
                movie={movie?.item}
                collection={movie?.collection}
                screen={"MovieInfo"}
                showVideo={!(isMobileSafari || isSafari || iosWeb)}
                addSeo
              />
            </div>
            <div className="movieInfoContainer">
              <div className="font-bold">
                <div className="titleBlock">
                  {/* {isMobile ? ( */}
                  <div>
                    <h2 className="title">{movie?.collection?.title}</h2>
                    {/* <h3 className="information">
                      {movie?.item?.duration} {movie?.collection?.rating} English {movie?.collection?.genre} {movie?.item?.rating_tags}
                    </h3> */}
                    <ul className="movie-details-wrap">
                      <li className="movie-details-list">
                        {movie?.item?.duration}
                      </li>
                      <li className="movie-details-list">
                        {movie?.collection?.rating}
                      </li>
                      <li className="movie-details-list">{movie?.collection?.language}</li>
                      <li className="movie-details-list">
                        {movie?.collection?.genre}
                      </li>
                      <li className="movie-details-list">
                        {movie?.item?.rating_tags}
                      </li>
                    </ul>
                  </div>
                  {showWatchNowButton() && (
                    <IonButton
                      size="large"
                      className="btnprimary"
                      color=""
                      onClick={() =>
                        handleContentWarning(movie?.item?.id, movie?.collection?.id)
                      }
                    >
                      <img className="play-icon" src="/icons/play.svg" alt="" />
                      Play
                    </IonButton>
                  )}
                  {/* <button
                    className="btnprimary"
                    onClick={() =>
                      onWatchClick(movie?.item?.id, movie?.collection?.id)
                    }
                  >
                    <img className="play-icon" src="/icons/play.svg" alt="" />
                    Watch Now
                  </button> */}
                </div>
                <div className="descriptionBlock">
                  <p>{movie?.collection?.description}</p>
                  <div className="cast-card-wrap">
                    <h5 className="cast-title">Cast:</h5>
                    <div className="cast-card-slider-wrap">
                      <Slider {...settings}>
                        {!isMobile
                          ? movie?.artists?.map((actor, key) => (
                              <CastCardMobileComponent key={key} actor={actor} />
                            ))
                          : movie?.artists?.map((actor, key) => (
                              <CastCardWebComponent key={key} actor={actor} />
                            ))}
                      </Slider>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <div>
                      {featuredMovies?.map((data, i) =>
                        data?.values?.length ? (
                          <MovieCarouselComponent
                            key={"carousel1" + i}
                            moviesList={data?.values}
                            name={"You might also like"}
                            screen={TRACK_EVENT.APP_USAGE.SCREEN.MOVIE_INFO}
                            isBanner={false}
                            loop={true}
                            showVideo={false}
                          />
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageWrapperComponent>
  );
};

const mapStateToProps = ({ user, persist }) => ({
  userId: user?.userId,
  isAuthUser: user?.isAuthUser,
  featuredMovieList: persist?.featuredMovies?.data,
});
const mapDispatchToProps = {
  saveFromAppAction: saveFromApp,
  setPlayerUrlAction: setPlayerUrl,
  getFeaturedMovieAction: getFeaturedMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(MovieInfoMobile);
