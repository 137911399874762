import React, { useState, useEffect, useMemo } from "react";
import "./Auth.scss";
import countryCodes from "country-codes-list";
import { BUSINESS_CONFIG, LOGO, SHOW_SOCIAL_AUTH } from "../../../../clientConfig";
import { Link } from "react-router-dom";
import { ROUTES } from "helpers/routeHelper";

const AuthenticationPageWeb = ({
  signIn,
  handleChange,
  user,
  forgetPassword,
  resetForm,
  signUp,
  error,
  errorMessage,
  successMessage,
  isForgetPasswordClicked,
  setIsForgetPasswordClicked,
  setMessage,
  setUser,
  resetError
}) => {
  const [authToggle, setAuthToggle] = useState(false);
  const [country, setCountry] = useState(null);

  const countryOptions = useMemo(
    () =>
      countryCodes
        .all()
        .map((x) => ({
          value: x.countryCallingCode,
          label: `${x.countryNameEn} (${x.countryCallingCode})`,
        })),
    []
  );

  return (
    <div className="authPage">
      <div className="signInHeader">
        <Link to={ROUTES.HOME} routerDirection="back">
          <img
            src={LOGO}
            alt=""
          />
        </Link>
        <div className="authBtnGroup">
          <button
            className={authToggle ? "authBtn" : "authBtn-active"}
            onClick={() => {
              setAuthToggle(false);
              resetForm(true);
              setMessage("");
              resetError();
              setIsForgetPasswordClicked(false);
            }}
          >
            Sign In
          </button>
          <button
            className={authToggle ? "authBtn-active" : "authBtn"}
            onClick={() => {
              setAuthToggle(true);
              setMessage("");
              resetForm(false);
              resetError();
              setIsForgetPasswordClicked(false);
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
      <div className="authForm">
        {authToggle ? (
          <>
            <div className="input-group">
              <input
                className="input-control"
                type="text"
                autoComplete="name"
                placeholder="Display Name"
                name="fullName"
                value={user.fullName}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.fullName}</span>
            </div>
            {/* <div className="input-group">
              <div className="numbercode-wrap">
                <Select
                  isSearchable={true}
                  placeholder="Country Code"
                  className="countrycode"
                  value={country}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      countryCode: e?.value,
                    });
                    setCountry(e);
                  }}
                  name="countryCode"
                  options={countryOptions}
                  placeholder="Country code"
                />
                <input
                  className="input-control"
                  type="text"
                  placeholder="Phone number"
                  name="phone"
                  value={user.phone}
                  onChange={handleChange}
                />
                <span className="error text-danger">{error?.countryCode}</span>
                <span className="error text-danger">{error?.phone}</span>
              </div>
            </div> */}
            <div className="input-group">
              <input
                className="input-control"
                type="text"
                autoComplete="username email"
                placeholder="Email"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.email}</span>
            </div>
            <div className="input-group">
              <input
                className="input-control"
                type="password"
                autoComplete="new-password"
                placeholder="Password"
                data-type="password"
                name="password"
                value={user.password}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.password}</span>
            </div>
            <div className="input-group">
              <input
                className="input-control"
                type="password"
                placeholder="Confirm Password"
                data-type="password"
                name="repeatPassword"
                value={user.repeatPassword}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.repeatPassword}</span>
            </div>
            {/* { !(Capacitor.isNativePlatform() && isPlatform("ios")) &&
            <div className="input-group">
              <input
                className="input-control"
                type="text"
                placeholder="Coupon Code"
                data-type="couponCode"
                name="couponCode"
                value={user.couponCode}
                onChange={handleChange}
              />
            </div>
            } */}
            <div className="input-group">
              <label className="privacypolicy">
                <input
                  type="checkbox"
                  name="acceptPrivacyPolicy"
                  value={user.acceptPrivacyPolicy}
                  onChange={handleChange}
                />
                <a target="_blank" href={BUSINESS_CONFIG.PrivacyPolicy}>
                  I have read the privacy policy & terms of use and agree to the
                  same
                </a>
              </label>
              <span className="error text-danger">
                {error?.acceptPrivacyPolicy}
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="input-group">
              <input
                className="input-control"
                type="text"
                placeholder="Email/Phone Number(with country code)"
                autoComplete="username email"
                id="user"
                name="email"
                readOnly={isForgetPasswordClicked}
                value={user.email}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.email}</span>
            </div>
            <div className="input-group">
              <input
                className="input-control"
                type="password"
                placeholder="Password"
                id="pass"
                autoComplete="new-password"
                data-type="password"
                name="password"
                value={user.password}
                onChange={handleChange}
              />
              <span className="error text-danger">{error?.password}</span>
            </div>
            <div className="input-group">
              {isForgetPasswordClicked && (
                <>
                  <div className="input-group">
                    <input
                      className="input-control"
                      type="password"
                      placeholder="Confirm Password"
                      data-type="password"
                      name="repeatPassword"
                      value={user.repeatPassword}
                      onChange={handleChange}
                    />
                    <span className="error text-danger">
                      {error?.repeatPassword}
                    </span>
                  </div>
                  <div className="input-group">
                    <input
                      className="input-control"
                      type="text"
                      placeholder="Passcode"
                      id="passcode"
                      autoComplete="otp"
                      name="passcode"
                      value={user.passcode}
                      onChange={handleChange}
                    />
                    <span className="error text-danger">{error?.passcode}</span>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {errorMessage && (
          <span className="error text-danger pb-2">{errorMessage}</span>
        )}
        {successMessage && (
          <span className="text text-success">{successMessage}</span>
        )}

        {authToggle ? (
          <button onClick={signUp}>Sign Up</button>
        ) : isForgetPasswordClicked ? (
          <button onClick={() => forgetPassword(true)}> Submit </button>
        ) : (
          <button onClick={signIn}> Sign In </button>
        )}
        {SHOW_SOCIAL_AUTH && (
          <div className="signWithSocial">
            <button>
              Signin with google <img src="/icons/google.svg" alt="" />{" "}
            </button>
            <button>
              Signin with facebook <img src="/icons/facebook.svg" alt="" />{" "}
            </button>
          </div>
        )}
        {authToggle ? (
          <a href="" className="forgotPassword">
            Already a member?
          </a>
        ) : (
          <span
            onClick={() => {
              forgetPassword();
            }}
            href=""
            className="forgotPassword"
          >
            forgot your password?
          </span>
        )}
      </div>
    </div>
  );
};

export default AuthenticationPageWeb;

const SignUpWithPhone = () => {
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");

  const [showOtp, setShowOtp] = useState(false);

  const handleSubmit = (isOtp) => {
    if (isOtp) {
    } else {
      // send otp
      setShowOtp(true);
    }
  };

  return (
    <>
      {showOtp ? (
        <>
          {/* <span className="error text-danger">{error?.email}</span> */}
          <input
            type="phone"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e?.target.value);
            }}
          />
          <button onClick={() => handleSubmit(false)}>
            Submit Phone Number
          </button>
        </>
      ) : (
        <>
          {/* <span className="error text-danger">{error?.email}</span> */}
          <input
            type="phone"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e?.target.value);
            }}
          />
          <button onClick={() => handleSubmit(false)}>
            Submit Phone Number
          </button>
        </>
      )}
    </>
  );
};
