import React, { useEffect, useState } from "react";
import { getSearchMovieResult } from "../../../../actions/services";

import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonLoading,
  useIonViewWillLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import "./SearchResult.scss";
import { searchOutline } from "ionicons/icons";
import { setPageTitle } from "../../../../helpers/seoHelper";
import {
  MovieCardNormalComponent,
  MovieCardShortComponent,
  HeaderComponent,
  FooterComponent,
} from "modules/common";
import { TRACK_EVENT } from "analytics/analyticsHelper";
import { useAnalytics } from "use-analytics";
import analytics from "analytics/analytics";

const SearchResult = ({ isMobile, match }) => {
  const { track, page, movieSearch} = useAnalytics();
  const [cursor, setCursor] = useState("");
  const [query, setQuery] = useState(match?.params?.searchKey || "");
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  const getMovieResult = () => {
    getSearchMovieResult(query, cursor)
      .then((res) => {
        if (res?.data?.success && res?.data?.data) {
          setItems(res?.data?.data?.results);
          setCursor(res?.data?.data?.next);
          if (res?.data?.data?.next) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
          setShowLoading(false);
        }
      })
      .finally(() => setShowLoading(false));
  };

  useEffect(() => {
    page(TRACK_EVENT.APP_USAGE.SCREEN.SEARCH_RESULT);
  },[]);

  useEffect(() => {
    setPageTitle("");
    setQuery(match?.params?.searchKey);
    getMovieResult();
  }, [match]);

  const getItems = () => {
    const cards = !isMobile
      ? items?.map((movie, i) => (
        <MovieCardNormalComponent key={i} movie={movie} screen={TRACK_EVENT.APP_USAGE.SCREEN.SEARCH_RESULT} query={query}/>
      ))
      : items?.map((movie, i) => (
        <MovieCardShortComponent key={i} movie={movie} screen={TRACK_EVENT.APP_USAGE.SCREEN.SEARCH_RESULT} query={query}/>
      ));
    return (
      <>
        <div className="row movie-card">{cards}</div>
      </>
    );
  };

  const fetchData = () => {
    getSearchMovieResult(query, cursor)
      .then((res) => {
        if (res?.data?.success && res?.data?.data) {
          setItems(items.concat(res?.data?.data?.results));
          setCursor(res?.data?.data?.next);
          if (res?.data?.data?.next) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        }
      })
      .finally(() => setShowLoading(false));
  };

  const enterPressed = (event) => {
    let code = event.keyCode || event.which;
    if ((query && code === 13) || event.key === "Enter") {
      setShowLoading(true);
      getMovieResult();
      setCursor("");
      track(
        TRACK_EVENT.APP_USAGE.NAME,
        TRACK_EVENT.APP_USAGE.getClickProps(
          TRACK_EVENT.APP_USAGE.CLICK_OPTIONS.SEARCH,
          TRACK_EVENT.APP_USAGE.SCREEN.SEARCH_RESULT,
          { SearchText : query }
        ));
        analytics.plugins.appCustom.movieSearch({ SearchText : query, Screen : TRACK_EVENT.APP_USAGE.SCREEN.SEARCH_RESULT});
    }
  };

  return (
    <>
      <IonPage className="ionPage searchresultpage">
        <HeaderComponent hideSearch />
        <div className="container searchcontainer">
          <div className="searchbar-wrap mt-4">
            <input
              className="searchbar"
              placeholder="Search"
              value={query}
              onChange={(e) => {
                setQuery(e?.target?.value);
              }}
              onKeyPress={(e) => enterPressed(e)}
            />
            <IonIcon
              onClick={() => {
                getMovieResult();
                setCursor("");
              }}
              icon={searchOutline}
            ></IonIcon>
          </div>
        </div>
        <IonContent
          id="main-content"
          className="main-body"
          scrollX="true"
          scrollY="true"
          fullscreen="false"
        >
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
          />
          <div className="container d-flex flex-column mb-4">
            {getItems()}
            {hasMore && (
              <IonButton
                size="small"
                color="secondary"
                onClick={() => fetchData()}
              >
                Load more
              </IonButton>
            )}
          </div>
          <FooterComponent />
        </IonContent>

      </IonPage>

    </>
  );
};

export default SearchResult;
